import React, { useState } from 'react'
import styled from 'styled-components'
import BlockLogo from '../images/BlockLogo.png'
import Close from '../images/cross.svg'
import { encode, validateEmail } from '../utils/utils'
import { Row, RowContainer } from './Common'

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${props => (props.open ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  background: rgba(199, 198, 202, 0.54);
  backdrop-filter: blur(15px);
  z-index: 100;
`

const Popup = styled.div`
  width: 48rem;
  height: 50rem;
  box-shadow: 13px 22px 38px 0px rgba(188, 164, 246, 0.2);
  border-radius: 4rem;
  background: #f8f3ff;
  position: relative;
  padding: 2rem;

  .block-logo {
    position: absolute;
    transform: translate(22%, -25%);
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
    border-radius: 0;

    .get-access-form {
      margin-top: 50rem;
    }

    .block-logo {
      transform: translate(15%, 15%);
    }
  }
`

const VioletText = styled.span`
  color: ${props => (props.isFormSubmitted ? '#28C97B' : '#6423ff')};
  font-size: 2.5rem;
  text-transform: capitalize;
  font-family: 'Inter';
  font-weight: 600;
  letter-spacing: 0.01rem;
  white-space: nowrap;
  margin: 0 0 2rem 0;
  @media only screen and (max-width: 600px) {
    font-size: 3.5rem;
  }
`

const HeaderText = styled.div`
  font-family: 'Inter';
  color: #797979;
  font-size: 1.6rem;
  white-space: normal;

  &.resend-email {
    font-size: 1.4rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 2.8rem;

    &.resend-email {
      font-size: 2.4rem;
    }
  }
`

export const GetAccessBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  width: 85%;
  height: 5rem;
  align-items: center;
  color: #fff;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 1.3rem;
  border-radius: 18px;
  gap: 0.5rem;
  letter-spacing: 0.7px;
  background: linear-gradient(276.11deg, #fea987 -4.52%, #6423ff 92.81%);
  cursor: pointer;
  transition: ease-out 0.5s;

  &:hover {
    background: linear-gradient(276.14deg, #fea987 -56.87%, #6423ff 57.77%);
    transition: ease-out 1s;
  }

  &:active {
    background: linear-gradient(276.14deg, #e6997a -56.87%, #5a1fe6 57.77%);
  }

  &:disabled {
    opacity: 0.3;
    cursor: unset;

    &:hover {
      background: linear-gradient(276.11deg, #fea987 -4.52%, #6423ff 92.81%);
    }
  }

  @media only screen and (max-width: 600px) {
    height: 8rem;
    font-size: 2.3rem;
  }
`

const Input = styled.input`
  width: 85%;
  height: 5.5rem;
  background: #fff;
  border-radius: 2.1rem;
  border: 1px solid #ddc6fb;
  margin: ${props =>
    props.isEmailErrorShown ? '2rem 0 0 0' : '2rem 0 1rem 0'};
  padding: 0 2rem;
  font-family: 'Inter';
  font-weight: 400;
  font-size: 1.4rem;

  &::placeholder {
    color: #c79dfe;
    font-family: 'Inter';
    font-weight: 400;
    font-size: 1.4rem;
  }

  @media only screen and (max-width: 600px) {
    height: 8rem;
    margin: 4rem 0 3rem 0;
    font-size: 2.4rem;

    &::placeholder {
      font-size: 2.4rem;
    }
  }
`

const TitleText = styled.span`
  font-family: 'Inter';
  color: #ff3f3e;
  font-size: 1.2rem;
  line-height: 2.2rem;
  text-align: center;
  width: 85%;
  font-weight: normal;

  @media only screen and (max-width: 600px) {
    line-height: 3.8rem;
    font-size: 2.4rem;

    &.value-title {
      font-size: 2.8rem;
      margin: 0.5rem 0;
    }
  }
`

const Line = styled.div`
  width: 85%;
  height: 0;
  border-top: 1px solid #c79dfe;
  margin: 3rem 0;
`

export const GetAccessPopup = ({
  open,
  close,
}: {
  open: boolean
  close: () => {}
}) => {
  const [email, setEmail] = useState('')
  const [isEmailErrorShown, showEmailError] = useState(false)
  const [isFormSubmitted, submitForm] = useState(false)


  const handleSubmit = (e) => {
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': 'Contact Form',
        email
      }),
    })
      .then(() => {
        submitForm(true)
      })
      .catch((error) => {
        console.log(error)
      })

    e.preventDefault()
  }

  return (
    <PageContainer open={open}>
      <Popup>
        <img className="block-logo" src={BlockLogo} />
        <RowContainer justify="flex-end">
          <img
            style={{ cursor: 'pointer' }}
            onClick={() => {
              submitForm(false)
              setEmail('')
              close()
            }}
            src={Close}
          />
        </RowContainer>
        <RowContainer
          className="get-access-form"
          margin="15rem 0 0 0"
          direction="column"
          align="center"
        >
          <VioletText isFormSubmitted={isFormSubmitted}>
            {isFormSubmitted && !isEmailErrorShown
              ? 'Welcome to waitlist!'
              : 'Join the waitlist!'}
          </VioletText>

          {isFormSubmitted && !isEmailErrorShown ? (
            <>
              <HeaderText>
                We have sent email to <strong>{email}</strong> to confirm the
                validity of our email address. After receiving the email follow
                the link provided to complete your registration in the waiting
                list.
              </HeaderText>
            </>
          ) : (
            <>
              <HeaderText>We will send you an email to </HeaderText>
              <HeaderText>educate you and prepare you for access.</HeaderText>
            </>
          )}
        </RowContainer>
        <RowContainer>
          <form
            name="Contact Form"
            method="POST"
            data-netlify="true"
            action="/success"
            onSubmit={handleSubmit}
            style={{ width: '100%' }}
          >
            <input type="hidden" name="form-name" value="Contact Form" />
            <RowContainer align="center" direction="column">
              {!isFormSubmitted || isEmailErrorShown ? (
                <Input
                  type="email"
                  name="email"
                  value={email}
                  placeholder={'Enter your e-mail'}
                  onChange={e => {
                    setEmail(e.target.value)
                  }}
                  className="required email"
                  onBlur={() => {
                    const isEmailValid = validateEmail(email)
                    if (isEmailValid) {
                      showEmailError(false)
                    } else if (!isEmailValid) {
                      showEmailError(true)
                    }
                  }}
                />
              ) : null}
              {isEmailErrorShown && (
                <TitleText >You have entered an invalid email address</TitleText>
              )}
            </RowContainer>
            {!isFormSubmitted ? (
              <div className="clear">
                <div
                  onClick={e => {
                    const isEmailValid = validateEmail(email)
                    if (isEmailValid) {
                      showEmailError(false)
                    } else if (!isEmailValid) {
                      showEmailError(true)
                    }
                    // setTimeout(() => submitForm(true), 400)
                  }}
                >

                  <GetAccessBtn
                    disabled={isEmailErrorShown || email === ''}
                    type="submit"
                    value="Subscribe"
                    name="subscribe"
                    className="button"
                  >
                    subscribe
                  </GetAccessBtn>
                </div>
              </div>
            ) : (
              <RowContainer direction="column">
                <Line />
                <HeaderText className="resend-email">
                  If you not got any email{' '}
                  <span
                    style={{ color: '#6423ff', cursor: 'pointer' }}
                    onClick={() => submitForm(false)}
                  >
                    Resend confirmation email
                  </span>
                </HeaderText>
              </RowContainer>
            )}
          </form>
        </RowContainer>
      </Popup>
    </PageContainer>
  )
}
