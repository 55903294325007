import React, { Component, Fragment } from 'react'
import { createGlobalStyle } from 'styled-components'
import { ThemeProvider } from 'styled-components'
import { Normalize } from 'styled-normalize'
import BuildingLogo from '../images/smallBack.png'
import theme from './theme'

import '../styles/fonts.css'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const BaseStyle = createGlobalStyle`

	${Normalize};

	* {
		box-sizing: border-box;
	}

	body,
	html {
	  font-size: 10px;
	  line-height: 1.4;
	  font-weight: 400;
	  min-width: 320px;
	  background-color: #F9F8FF;
	  margin-left: auto;
	  margin-right: auto;
	  width: 100%;
	  height: 100%;
	  -webkit-font-smoothing: antialiased;
		overflow: scroll;
		background: #F9F8FF;
	}



	html {
		position: relative;
	}

	body {
		/* background-color: ${({ theme }) => theme.colors.background}; */
		overflow-x: hidden;
		overflow-y: scroll;
		margin: 0;
		padding: 0;

		@media (min-width: 1920px){
    max-width: 1920px;
    margin: 0 auto;
		}
	}

	body::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
  body::-webkit-scrollbar-thumb {
    background: transparent;
  }
  body::-webkit-scrollbar-track {
    background: transparent;
  }


	a {
		text-decoration: none;
		cursor: pointer;
		color: white;
	}

	button, input {
		box-shadow: none;
		border: none;
		outline: none;
	}

	p {
		margin: 0;
		font-size: 1.175rem;
	}

	h1, h2, h3, h4, h5, h6 {
	
		font-family: 'Avenir Next Bold', sans-serif;
	}

	.ReactModal__Overlay *::-webkit-scrollbar {
		display: none;
	}

	.close-modal-button {
		position: absolute;
	    right: 2rem;
    	top: 1rem;
		cursor: pointer;
	}



	.burger-menu {
		display: none;
		position: fixed;
		top:0rem;
		left: 0;
		z-index: 10000;
		width: 100%;
		height: 100%;
		background: #17181A;

		flex-direction: column;
		align-items: center;
		justify-content: flex-start;

		a {
			color: #fff;
			font-family: 'Avenir Next Medium';
			font-size: 3.5rem;
			display: inline-block;
			margin: 1rem 0;

			/* &:last-child {
				width: 80%;

				button {
					width: 100%;
					margin-top: 2rem;
					padding: 1rem 2rem;
				}
			} */
		}

		@media only screen and (max-width: 1250px) {
			a.link {
				font-size: 2rem;
				margin: .5rem;
			}

			a.sub-link {
				font-size: 1.8rem;
				margin: .5rem;
			}
		}

		@media only screen and (max-width: 1024px) {
			a {
				font-size: 4rem;
				margin: 1.5rem;
			}

			a.link {
				font-size: 2.5rem;
				margin: .5rem;
			}

			a.sub-link {
				font-size: 2.2rem;
				margin: .5rem;
			}
		}

		@media only screen and (max-width: 768px) {
			a {
				font-size: 4.5rem;
				margin: 2rem;
			}

			a.link {
				font-size: 4.5rem;
				margin: 2rem;
			}

			a.sub-link {
				font-size: 4rem;
				margin: .5rem;
			}
		}

		@media only screen and (max-width: 600px) {
		
			a {
				font-size: 3.5rem;
				margin: 2.5rem;
			}

			a.sub-link {
				height: 5rem;
				font-size: 3.5rem;
				margin: 1rem 4rem;
			}
		}

		
	}

	.ReactModal__Content a {
		color: blue;
	}

	@media only screen and (min-width: 1520px) {
		html, body {
			font-size: 11px;
		}
	}

	@media only screen and (min-width: 1620px) {
		html, body {
			font-size: 11.5px;
		}
	}

	@media only screen and (min-width: 1740px) {
		html, body {
			font-size: 12px;
		}
	}

	@media only screen and (min-width: 1820px) {
		html, body {
			font-size: 12.5px;
		}
	}

	@media only screen and (min-width: 1920px) {
		html, body {
			font-size: 14px;
		}
	}

	/* @media only screen and (min-width: 2020px) {
		html, body {
			font-size: 14.5px;
		}
	}

	@media only screen and (min-width: 2120px) {
		html, body {
			font-size: 15px;
		}
	} */

	/* @media only screen and (min-width: 2220px) {
		html, body {
			font-size: 15.5px;
		}
	}

	@media only screen and (min-width: 2320px) {
		html, body {
			font-size: 16px;
		}
	}

	@media only screen and (min-width: 2420px) {
		html, body {
			font-size: 16.5px;
		}
	}

	@media only screen and (min-width: 2520px) {
		html, body {
			font-size: 17px;
		}
	} */

	/* 1440 - 10px, start */
	@media only screen and (max-width: 978px) {
		html, body {
			font-size: 9px;
		}
	}
	@media only screen and (max-width: 768px) {
		html, body {
			font-size: 8px;
		}
	}
	@media only screen and (max-width: 580px) {
		html, body {
			font-size: 7px;
		}
	}
	@media only screen and (max-width: 480px) {
		html, body {
			font-size: 6px;
		}
	}

	.loader {
		font-size: 10px;
		margin: 50px auto;
		text-indent: -9999em;
		width: 10em;
		height: 10em;
		border-radius: 50%;
		background: #7380EB;
		background: -moz-linear-gradient(left, #7380EB 10%, rgba(255, 255, 255, 0) 42%);
		background: -webkit-linear-gradient(left, #7380EB 10%, rgba(255, 255, 255, 0) 42%);
		background: -o-linear-gradient(left, #7380EB 10%, rgba(255, 255, 255, 0) 42%);
		background: -ms-linear-gradient(left, #7380EB 10%, rgba(255, 255, 255, 0) 42%);
		background: linear-gradient(to right, #7380EB 10%, rgba(255, 255, 255, 0) 42%);
		position: relative;
		-webkit-animation: load3 1.4s infinite linear;
		animation: load3 1.4s infinite linear;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
	  }
	  .loader:before {
		width: 50%;
		height: 50%;
		background: #7380EB;
		border-radius: 100% 0 0 0;
		position: absolute;
		top: 0;
		left: 0;
		content: '';
	  }
	  .loader:after {
		background: rgb(231, 236, 243);
		width: 75%;
		height: 75%;
		border-radius: 50%;
		content: '';
		margin: auto;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	  }
	  @-webkit-keyframes load3 {
		0% {
		  -webkit-transform: rotate(0deg);
		  transform: rotate(0deg);
		}
		100% {
		  -webkit-transform: rotate(360deg);
		  transform: rotate(360deg);
		}
	  }
	  @keyframes load3 {
		0% {
		  -webkit-transform: rotate(0deg);
		  transform: rotate(0deg);
		}
		100% {
		  -webkit-transform: rotate(360deg);
		  transform: rotate(360deg);
		}
	  }

	  @media only screen and (max-width: 978px) {
		.loader {
			width: 7em;
			height: 7em;
		}
	}

	.gatsby-image-wrapper {
		width: 100%;
	}

	.slick-slide {
		visibility: hidden;
	  }
	
	.slick-slide.slick-current {
		visibility: visible;
	}

	img {
		image-rendering: -moz-crisp-edges;
		image-rendering: -o-crisp-edges;
		image-rendering: -webkit-optimize-contrast;
		-ms-interpolation-mode: nearest-neighbor;
	}
`

export default class StyleProvider extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          {/* <FontsStyle /> */}
          <BaseStyle />
          {this.props.children}
        </Fragment>
      </ThemeProvider>
    )
  }
}
