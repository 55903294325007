import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, RowContainer } from '../../components/Common'
import { Slider } from '../../components/Slider/Slider'
import Arrow from '../../images/arrow.svg'
import SolanaIcon from '../../images/solana.svg'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0rem 18rem 10rem 18rem;

  @media only screen and (min-width: 1600px) {
    padding: 5rem 18rem 6rem 18rem;
  }

  @media only screen and (max-width: 600px) {
    padding: 10rem 3rem;
  }
`

const Title = styled.span`
  font-family: 'Montserrat Bold';
  font-weight: 700;
  color: #1d1d1d;
  font-size: 4rem;
  line-height: 5rem;
  white-space: nowrap;

  @media only screen and (min-width: 1600px) {
    font-size: 3.5rem;
    line-height: 5rem;
  }
  
  @media only screen and (max-width: 600px) {
    font-size: 4.7rem;
    line-height: 6rem;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 80%;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`
const TitleContainerMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media only screen and (min-width: 600px) {
    display: none;
  }
`

const Header = styled.span`
  font-family: 'Montserrat Bold';
  font-weight: 700;
  color: #1d1d1d;
  font-size: 3rem;
  line-height: 4rem;

  @media only screen and (max-width: 600px) {
    font-size: 5rem;
    margin: 3rem 0;
  }
`

const Text = styled.span`
  font-family: 'Inter';
  font-weight: ${props => props.weight || '500'};
  color: ${props => props.color || '#1D1D1D'};
  font-size: ${props => props.fontSize || '1.5rem'};
  text-align: left;
  margin-top: ${props => props.marginTop || '1rem'};

  @media only screen and (max-width: 600px) {
    font-size: 3rem;
    margin: 4rem 0;
  }
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 10rem 0;

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
    margin: 0;
  }
`
const TextBlock = styled.div`
  width: ${props => props.width || '47%'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  @media only screen and (max-width: 600px) {
    width: ${props => props.width || '100%'};
  }
`

const TitleText = styled.span`
  font-family: 'Inter';
  color: ${props => props.color || '#717171'};
  font-size: ${props => props.fontSize || '1.5rem'};
  line-height: ${props => props.height || '2.5rem'};
  text-align: left;
  margin-top: ${props => props.marginTop || '1rem'};
  font-weight: ${props => props.weight || 'normal'};

  @media only screen and (max-width: 600px) {
    line-height: 3.8rem;
    font-size: 2.4rem;

    &.value-title {
      font-size: 2.8rem;
      margin: 0.5rem 0;
    }
  }
`

const Line = styled.div`
  width: 100%;
  height: 0;
  border: 1px solid #c9c9c9;
  margin: 2rem 0 0 0;
`


export const GetAccessBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  width: 100%;
  height: 4rem;
  align-items: center;
  color: #fff;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 1.3rem;
  border-radius: 18px;
  gap: 0.5rem;
  letter-spacing: 0.7px;
  background: linear-gradient(276.11deg, #fea987 -4.52%, #6423ff 92.81%);
  margin-top: 2rem;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    height: 6rem;
    margin: 4rem 0 1rem 0;
    font-size: 2rem;
  }
`



const TextWithBorder = ({
  number,
  title,
  text,
}: {
  number: string
  title: string
  text: string
}) => {
  return (
    <RowContainer direction="column" align="flex-start">
      <TitleText marginTop="2rem">
        <span style={{ fontWeight: 600, color: '#1d1d1d' }}>{number}</span>{' '}
        <span style={{ fontWeight: 600, color: '#6423FF' }}>{title}</span>{' '}
        {text}
      </TitleText>
      <Line />
    </RowContainer>
  )
}

export const DeFiInformation = () => {
  return (
    <Container>
      <TitleContainer>
        <Title>Millions of people are earning high yields</Title>
        <Title>through DeFi, now you can with a click.</Title>
      </TitleContainer>
      <TitleContainerMobile>
        <Title>Millions of people are</Title>
        <Title>earning high yields</Title>
        <Title>through DeFi, now you</Title>
        <Title>can with a click.</Title>
      </TitleContainerMobile>
      <ContentContainer>
        <TextBlock>
          <Header>How is this possible?</Header>
          <TitleText marginTop="2rem">
            Instead of transacting through banks and stock exchanges, people
            trade directly with one another. This is the power of decentralised
            finance. In order for customers to transact, much like a bank, there
            must be deposits on hand, except in DeFi your assets never leave
            your wallet. The fees and rewards generated are then shared with
            you, instead of going to a bank or brokerage.
          </TitleText>
          <Text weight="600">
            To simplify your options, here is a comparison.
          </Text>
          <TextWithBorder
            number={'1.'}
            title={'FOUNDATION'}
            text=" works much like a savings account."
          />
          <TextWithBorder
            number={'2.'}
            title={'DYNAMIC'}
            text=" is similar to buying a stock with a fixed dividend."
          />
          <TextWithBorder
            number={'3.'}
            title={'COFFEE MONEY'}
            text=" is like investing in a start-up, in this case Aldrin and you also receive a portion of the revenue as a reward."
          />
          <Text marginTop="2rem" weight="600">
            You can claim your earnings, your funds or compound our earnings,
            monthly.
          </Text>
          <TitleText height="2rem" color="#9E9E9E">
            Any building block can be stopped at any time. Check your building block monthly.
          </TitleText>
        </TextBlock>
        <Slider />
      </ContentContainer>
    </Container>
  )
}
