import { API_URL } from "./config"

const getDexTokensPricesQuery = `
    query getDexTokensPrices($symbols: [String]) {
        getDexTokensPrices (symbols: $symbols) {
            symbol
            price
        }
    }
`

const getDexTokensPrices = async () => {
  return await fetch(`${API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      operationName: 'getDexTokensPrices',
      query: getDexTokensPricesQuery,
      variables: {
        symbols: ['RIN']
      }
    }),
  })
    .then(data => data.json())
    .then(data => {
      return data
    })
}


export const getCCAIPriceValue = async () => {
  const price = await getDexTokensPrices()
  return price.data.getDexTokensPrices?.find(el => el?.symbol === 'RIN')?.price
}
