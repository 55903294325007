import React from 'react'
import styled from 'styled-components'

import YahooFinance from '../../images/yahooFinance.svg'
import CoinTelegraph from '../../images/coinTelegraph.svg'
import TheIndependent from '../../images/theIndependent.svg'
import Investing from '../../images/investing.svg'

import BlackYahoo from '../../images/blackYahoo.svg'
import BlackCoinTelegraph from '../../images/blackCoinGraph.svg'
import BlackTheIndependent from '../../images/blackTheIndependent.svg'
import BlackInvesting from '../../images/blackInvesting.svg'

import { RowContainer } from '../../components/Common'

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 10rem 10rem 18rem 10rem;

  .yahoo {
    position: absolute;
    left: 0;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  .coinTg {
    position: absolute;
    left: 0;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  .invest {
    position: absolute;
    left: 0;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  .independ {
    position: absolute;
    left: 0;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  .yahooBlack {
    position: absolute;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .coinTgBlack {
    position: absolute;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .blackInvest {
    position: absolute;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .blackIndepend {
    position: absolute;
    left: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  a {
    position: relative;
    &:hover {
      .yahooBlack {
        opacity: 1;
      }

      .coinTgBlack {
        opacity: 1;
      }

      .blackInvest {
        opacity: 1;
      }

      .blackIndepend {
        opacity: 1;
      }

      .yahoo {
        opacity: 0;
      }

      .coinTg {
        opacity: 0;
      }

      .invest {
        opacity: 0;
      }

      .independ {
        opacity: 0;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    display: none;
  }
`

const ContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 7rem 10rem 5rem 10rem;
  div {
    a {
      margin: 4rem 0;
    }
  }

  @media only screen and (min-width: 600px) {
    display: none;
  }
`

const Title = styled.div`
  font-family: 'Inter';
  font-weight: 700;
  color: #bbbbc0;
  font-size: 2.4rem;
  line-height: 5rem;
`

export const Investors = () => {
  return (
    <>
      <Container>
        <Title>Featured In</Title>
        <a
          href={
            'https://finance.yahoo.com/news/10-best-cryptocurrencies-invest-1000-084407347.html'
          }
          rel="noopener noreferrer"
          target="_blank"
          style={{ width: '9%' }}
        >
          <img className="yahoo" width="100%" src={YahooFinance} />
          <img className="yahooBlack" width="100%" src={BlackYahoo} />
        </a>
        <a
          href={
            'https://cointelegraph.com/news/portfolio-rebalancing-through-defi-must-be-simplified-to-see-adoption'
          }
          rel="noopener noreferrer"
          target="_blank"
          style={{ width: '11%' }}
        >
          <img className="coinTg" width="100%" src={CoinTelegraph} />
          <img className="coinTgBlack" width="100%" src={BlackCoinTelegraph} />
        </a>
        <a
          href={
            'https://www.investing.com/news/cryptocurrency-news/how-hal-and-aldrin-are-helping-to-keep-crypto-assets-secure-during-bear-markets-2780700'
          }
          rel="noopener noreferrer"
          target="_blank"
          style={{ width: '15%' }}
        >
          <img className="invest" width="100%" src={Investing} />
          <img className="blackInvest" width="100%" src={BlackInvesting} />
        </a>
        <a
          href={
            'https://www.independent.co.uk/tech/solana-crypto-price-sol-bitcoin-2021-b1917072.html'
          }
          rel="noopener noreferrer"
          target="_blank"
          style={{ width: '22%' }}
        >
          <img className="independ" width="100%" src={TheIndependent} />
          <img
            className="blackIndepend"
            width="100%"
            src={BlackTheIndependent}
          />
        </a>
      </Container>
      <ContainerMobile>
        <RowContainer>
          <Title style={{ fontSize: '3.4rem', marginBottom: '2rem' }}>
            Featured In
          </Title>
        </RowContainer>
        <RowContainer justify="space-between">
          <a
            href={
              'https://finance.yahoo.com/news/10-best-cryptocurrencies-invest-1000-084407347.html'
            }
            rel="noopener noreferrer"
            target="_blank"
            style={{ width: '45%' }}
          >
            <img className="yahoo" width="100%" src={YahooFinance} />
          </a>
          <a
            href={
              'https://cointelegraph.com/news/portfolio-rebalancing-through-defi-must-be-simplified-to-see-adoption'
            }
            rel="noopener noreferrer"
            target="_blank"
            style={{ width: '45%' }}
          >
            {' '}
            <img className="coinTg" width="100%" src={CoinTelegraph} />
          </a>
        </RowContainer>
        <RowContainer>
          <a
            href={
              'https://www.investing.com/news/cryptocurrency-news/how-hal-and-aldrin-are-helping-to-keep-crypto-assets-secure-during-bear-markets-2780700'
            }
            rel="noopener noreferrer"
            target="_blank"
            style={{ width: '100%' }}
          >
            {' '}
            <img className="invest" width="100%" src={Investing} />
          </a>
        </RowContainer>
        <RowContainer>
          <a
            href={
              'https://www.independent.co.uk/tech/solana-crypto-price-sol-bitcoin-2021-b1917072.html'
            }
            rel="noopener noreferrer"
            target="_blank"
            style={{ width: '70%' }}
          >
            {' '}
            <img className="independ" width="100%" src={TheIndependent} />
          </a>
        </RowContainer>
      </ContainerMobile>
    </>
  )
}
