import React, { useState } from 'react'
import styled from 'styled-components'
import MainLogo from '../../images/mainLogo.png'
import { Row } from '../../components/Common'
import { GetAccessPopup } from '../../components/GetAccessPopup'
import Back from '../../images/smallBack.png'

const MainContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: row;
  @media only screen and (max-width: 600px) {
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }
`

const MainImage = styled.div`
  width: 45%;
  position: relative;
  left: 5%;
  bottom: 20%;
  img {
    position: absolute;
    width: 100%;
  }

  @media only screen and (min-width: 1600px) {
    width: 40%;
    left: 2%;
  }

  @media only screen and (max-width: 600px) {
    height: 42rem;
    width: 85%;
    top: 5rem;
  }
`

const MainHeaderContainer = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  padding: 10rem 18rem;
  height: calc(100vh - 8rem);
  width: 100%;
  background-image: url(${Back});
  background-size: contain;

  @media only screen and (max-width: 2000px) {
    gap: 14rem;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    margin-top: 0rem;
    padding: 0 3rem;
    margin-bottom: 8rem;
  }
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 60%;

  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`

const HeadingContainerDesktop = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`

const HeadingContainerMobile = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 0 4rem 0;

  @media only screen and (min-width: 600px) {
    display: none;
  }
`

const Heading = styled.span`
  font-weight: 600;
  font-family: 'Inter';
  color: #282828;
  font-size: 6rem;
  letter-spacing: 0.01em;
  position: relative;

  @media only screen and (max-width: 1600px) {
    font-size: 5.5rem;
    line-height: 6rem;
  }

  @media only screen and (min-width: 1600px) {
    font-size: 5rem;
    line-height: 6rem;
  }

  @media only screen and (max-width: 1550px) and (min-width: 1510px) {
    font-size: 3.8rem;
    line-height: 3.8rem;
  }

  @media only screen and (max-width: 1404px) {
    font-size: 3.4rem;
    line-height: 3.4rem;
  }

  @media only screen and (max-width: 1205px) {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }

  @media only screen and (max-width: 600px) {
    text-align: left;
    font-size: 6.5rem;
    line-height: 7.6rem;
    font-weight: 700;
  }
`

const HeaderText = styled.div`
  font-family: 'Inter';
  color: #797979;
  font-size: 1.6rem;
  white-space: nowrap;

  @media only screen and (max-width: 600px) {
    white-space: normal;
    font-size: 2.8rem;
  }
`

const GetAccessRow = styled.div`
  display: flex;
  gap: 3.6rem;
`

export const GetAccessBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  width: 18rem;
  height: 5rem;
  align-items: center;
  color: #fff;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 1.3rem;
  border-radius: 18px;
  gap: 0.5rem;
  letter-spacing: 0.7px;
  cursor: pointer;
  background: linear-gradient(276.11deg, #fea987 -4.52%, #6423ff 92.81%);
  opacity: 0.8;
  transition: 0.4s;

  &:hover {
    background: linear-gradient(276.14deg, #fea987 -56.87%, #6423ff 57.77%);
    opacity: 1;
  }

  &:active {
    background: linear-gradient(276.14deg, #e6997a -56.87%, #5a1fe6 57.77%);
  }

  @media only screen and (max-width: 600px) {
    font-size: 2rem;
    width: 40%;
    height: 7rem;
    border-radius: 3rem;
  }
`

const InfoBlock = styled.div`
  width: 65%;
  display: flex;
  justify-content: space-between;

  @media (min-width: 2000px) {
    margin: 5rem 0 0 0px;
  }

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    width: 100%;
    &.info-box {
      justify-content: space-between;
    }
  }
`

const VioletText = styled.span`
  color: #6423ff;
  font-size: 2.3rem;
  text-transform: capitalize;
  font-family: 'Inter';
  font-weight: 600;
  letter-spacing: 0.01rem;
  white-space: nowrap;
`
const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;

  &.violet-box {
    margin: 0 4rem 0 0;
  }
  @media only screen and (max-width: 600px) {
    &.violet-box {
      flex-direction: row;
      justify-content: flex-start;
      margin: 2rem 0 4rem 0;
    }

    &.info-box {
      align-items: flex-start;
    }
  }
`

const TitleText = styled.div`
  font-family: 'Inter';
  color: #797979;
  font-size: 1.5rem;
  @media only screen and (max-width: 600px) {
    font-size: 1.8rem;
    white-space: normal;
    text-align: left;

    &.info {
      font-size: 2.1rem;
    }
  }
`

const BoldText = styled.span`
  color: #1d1d1d;
  font-size: 4.2rem;
  text-transform: capitalize;
  font-family: 'Montserrat Bold';
  font-weight: 700;
  letter-spacing: 0.01rem;
  white-space: nowrap;
`

const VerticalLine = styled.div`
  width: 0;
  height: 6rem;
  border-left: 1px solid #6423ff;
  margin-right: 3rem;
`

const Main = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  return (
    <>
      <MainHeaderContainer>
        <MainContainer>
          <HeaderContainer>
            <HeadingContainerDesktop>
              <Heading>The easiest way to</Heading>
              <Heading>earn passive income</Heading>
            </HeadingContainerDesktop>
            <HeadingContainerMobile>
              <Heading>The easiest</Heading>
              <Heading>way to earn</Heading>
              <Heading>passive income</Heading>
            </HeadingContainerMobile>
            <HeadingContainerDesktop>
              <HeaderText>
                Decentralized finance (DeFi) is complicated and can take months
              </HeaderText>
              <HeaderText>
                or even years to learn the ins and outs. We don’t want anyone
                left behind,
              </HeaderText>
              <HeaderText>
                that’s why we made it as easy as a few clicks.
              </HeaderText>
            </HeadingContainerDesktop>
            <HeadingContainerMobile>
              <HeaderText>
                Decentralized finance (DeFi) is complicated and can take months
                or even years to learn the ins and outs. We don’t want anyone
                left behind, that’s why we made it as easy as a few clicks.
              </HeaderText>
            </HeadingContainerMobile>
            <GetAccessRow>
              <GetAccessBtn
                onClick={() => {
                  setIsPopupOpen(true)
                }}
              >
                get access
              </GetAccessBtn>
            </GetAccessRow>
          </HeaderContainer>{' '}
          <MainImage>
            <img src={MainLogo} />
          </MainImage>
        </MainContainer>
        <InfoBlock>
          <TextColumn className="violet-box">
            <VioletText>Building</VioletText>
            <VioletText>Block by </VioletText>
            <VioletText> Aldrin</VioletText>
          </TextColumn>
          <Row className="info-box" justify="flex-start" wrap="nowrap">
            {' '}
            <TextColumn style={{ marginRight: '3rem' }}>
              <TitleText className="info">Total Assets earning</TitleText>
              <TitleText className="info">Passive Income</TitleText>
              <BoldText>$90.42m</BoldText>
            </TextColumn>
            <VerticalLine />
            <TextColumn>
              <TitleText className="info">Earn Passive Income</TitleText>
              <TitleText className="info">At average</TitleText>
              <BoldText>8.75% APR</BoldText>
            </TextColumn>
          </Row>
        </InfoBlock>
      </MainHeaderContainer>
      <GetAccessPopup
        open={isPopupOpen}
        close={() => {
          setIsPopupOpen(false)
        }}
      />
    </>
  )
}

export default Main
