import { graphql, Link } from 'gatsby'
import React, { Component } from 'react'
import styled from 'styled-components'

import { getCCAICirculationSupply } from '../utils/getCCAICirculationSupply'
import { getCCAIPriceValue } from '../utils/getCCAIPriceValue'
import { getStakingAPR } from '../utils/getStakingAPR'

import Layout from '../components/Layout'
import Main from '../fragments/index/Main'
import AldrinLabs from '../fragments/index/AldrinLabs'
import Pools from '../fragments/index/Pools'
import { Investors } from '../fragments/index/Investors'
import { HowToStart } from '../fragments/index/HowToStart'
import { DeFiInformation } from '../fragments/index/Information'

class Index extends Component {
  state = {
    tradingFee: false,
    supply: 0,
    price: 0,
    marketcap: 0,
    totalStaked: 0,
    stakingAPR: 0,
  }

  async componentDidMount() {
    localStorage.setItem('version', '1.0.7')
    document.body.scrollTo(0, 0)

    const [price, circulationSupply, stakingAPR] = await Promise.all([getCCAIPriceValue(), getCCAICirculationSupply(), getStakingAPR()])
    const marketcap = price * circulationSupply
    this.setState({ marketcap, price, supply: circulationSupply, totalStaked: stakingAPR.totalStaked, stakingAPR: stakingAPR.totalStakingAPR })
  }

  render() {
    const { price, marketcap, supply, stakingAPR, totalStaked } = this.state

    return (
      <>
        <Main />
        <Investors />
        <DeFiInformation />
        <HowToStart />
      </>
    )
  }
  onPlanChosen(plan) {
    localStorage.setItem('chosen_plan_main', JSON.stringify(plan))
  }
}

const LayoutContainer = props => {
  return (
    <Layout>
      <Index {...props} />
    </Layout>
  )
}

export default LayoutContainer

export const Screenshot = graphql`
  fragment Screenshot on File {
    childImageSharp {
      fluid(
        maxWidth: 3080
        quality: 100
        webpQuality: 100
        srcSetBreakpoints: [
          320
          480
          760
          880
          980
          1024
          1120
          1200
          1400
          1600
          1919
          2200
          2500
        ]
      ) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
  }
`

export const SmallerScreenshot = graphql`
  fragment SmallerScreenshot on File {
    childImageSharp {
      fluid(
        maxWidth: 2600
        quality: 90
        webpQuality: 100
        srcSetBreakpoints: [320, 880, 1400, 1900, 2600]
      ) {
        ...GatsbyImageSharpFluid_withWebp
        presentationWidth
      }
    }
  }
`

export const featuresQuery = graphql`
  query {
    pnlIndex: file(relativePath: { eq: "features/Performance.webp" }) {
      ...Screenshot
    }
    rebalanceIndex: file(relativePath: { eq: "features/Rebalance.webp" }) {
      ...Screenshot
    }
    calendarIndex: file(relativePath: { eq: "features/Analyse.webp" }) {
      ...Screenshot
    }
    mainChart: file(relativePath: { eq: "chartHires.png" }) {
      ...Screenshot
    }
  }
`
