import styled from 'styled-components'
import { Link } from 'gatsby'

export const Banner = styled.div`
  height: 17rem;
  width: 100%;
  display: flex;
  padding: 5rem 4rem;
  background: linear-gradient(45deg, rgb(32, 82, 204), rgb(79, 121, 204));

  @media (max-width: 600px) {
    height: 17rem;
  }

  @media (min-width: 700px) {
    display: none;
  }
`
export const StyledDexLink = styled.a`
  width: 18rem;
  height: 7rem;
  border-radius: 1.2rem;
  background: linear-gradient(45deg, rgb(32, 82, 204), rgb(79, 121, 204));
  color: #fff;
  font-size: 2rem;
  align-items: center;
  /* text-align: center; */
  justify-content: center;
  display: flex;
  font-family: Avenir Next Light;
`

export const BigText = styled.span`
  color: #fff;
  font-family: Avenir Next Bold;
  font-size: 6rem;
  height: 7rem;
  @media only screen and (max-width: 1100px) {
    font-size: 4rem;
    height: 5rem;
  }
`

export const EmptyText = styled.span`
  color: transparent;
  -webkit-text-stroke: 1px white;
  font-family: Avenir Next Bold;
  font-size: 5rem;
  @media only screen and (max-width: 1100px) {
    font-size: 3rem;
  }
`

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => props.justify || 'center'};
  flex-direction: ${props => props.direction || 'row'};
  align-items: ${props => props.align || 'center'};
  text-align: center;

  padding: 4rem 10rem 11rem 10rem;
  background: ${props => props.theme.colors.background};

  @media only screen and (min-width: 2200px) {
    max-width: 1920px;
    padding-left: 0;
    padding-right: 0;
    margin: 0 auto;
  }

  @media only screen and (max-width: 1400px) {
    padding-right: 8rem;
    padding-left: 8rem;
  }

  @media only screen and (max-width: 1280px) {
    padding-right: 8rem;
    padding-left: 8rem;
  }
  @media only screen and (max-width: 768px) {
    padding-right: 6rem;
    padding-left: 6rem;
  }

  @media only screen and (max-width: 600px) {
    padding-top: 5rem;
  }

  @media only screen and (max-width: 580px) {
    padding-right: 5rem;
    padding-left: 5rem;
  }

  @media only screen and (max-width: 480px) {
    padding-right: 4rem;
    padding-left: 4rem;
  }
`

export const Row = styled.div`
  display: flex;
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  flex-wrap: ${props => props.wrap || 'wrap'};
  justify-content: ${props => props.justify || 'center'};
  flex-direction: ${props => props.direction || 'row'};
  align-items: ${props => props.align || 'center'};
  margin: ${props => props.margin || '0'};
  text-align: center;
`

export const RowContainer = styled(Row)`
  width: 100%;

  @media only screen and (max-width: 620px) {
    width: 100%;

    // div:first-child {
    //   padding-top: 0rem;
    // }
  }
`

export const MediumText12 = styled.span`
  font-family: Avenir Next Demi Bold;
  font-size: 1.2rem;
  color: ${props => props.color || props.theme.colors.textGray};
  @media only screen and (max-width: 600px) {
    font-size: 2.2rem;
  }
`

export const MediumText16 = styled.span`
  font-family: 'Avenir Next Medium';
  font-size: 1.6rem;
  color: ${props => props.color || '#16253D'};
`

export const NormalText10 = styled.span`
  font-family: 'Avenir Next';
  font-size: 1rem;
  zindex: 3;
  @media only screen and (max-width: 600px) {
    width: 100%;
    position: relative;
    font-size: 1.6rem;
  }

  color: ${props => props.color || props.theme.colors.textGray};
`

export const NormalText12 = styled.span`
  font-family: 'Avenir Next';
  font-size: 1.2rem;
  color: ${props => props.color || props.theme.colors.white};
`

export const NormalText14 = styled.span`
  font-family: 'Avenir Next';
  font-size: 1.4rem;
  color: ${props => props.color || props.theme.colors.white};
`

export const NormalText16 = styled.span`
  font-family: 'Avenir Next';
  font-size: 1.6rem;
  color: ${props => props.color || '#3A475C'};
  line-height: 100%;

  ${({ needMedia = true }) =>
    needMedia
      ? `
      @media only screen and (max-width: 1300px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 2rem;
  }

  @media only screen and (max-width: 400px) {
    font-size: 2.4rem;
  }

  @media only screen and (max-width: 350px) {
    font-size: 2.2rem;
  }`
      : ''}
`

export const NormalText20 = styled.span`
  font-family: 'Avenir Next';
  font-size: 2rem;
  color: ${props => props.color || '#3A475C'};

  @media only screen and (max-width: 576px) {
    font-size: 2.2rem;
  }

  @media only screen and (max-width: 480px) {
    font-size: 2.4rem;
  }
`

export const NormalText24 = styled.span`
  font-family: 'Avenir Next';
  font-size: 2.4rem;
  color: ${props => props.color || props.theme.colors.textGray};

  @media only screen and (max-width: 978px) {
    font-size: 2.3rem;
  }
  @media only screen and (max-width: 768px) {
    font-size: 2.2rem;
  }
  @media only screen and (max-width: 576px) {
    font-size: 2.1rem;
  }
  @media only screen and (max-width: 480px) {
    font-size: 2rem;
  }
`

export const NormalText32 = styled.span`
  font-family: 'Avenir Next';
  font-size: 3.2rem;
  color: ${props => props.color || '#EAEEF2'};
`

export const BoldTitle48 = styled.h3`
  font-family: 'Avenir Next Bold';
  font-size: 4.8rem;
  color: ${props => props.color || props.theme.colors.blue};
  margin: 0;
  line-height: 100%;

  @media only screen and (max-width: 978px) {
    font-size: 4.4rem;
  }
  @media only screen and (max-width: 768px) {
    font-size: 4.2rem;
  }
  @media only screen and (max-width: 576px) {
    font-size: 4rem;
  }
  @media only screen and (max-width: 480px) {
    font-size: 3.8rem;
  }
`

export const BoldTitle32 = styled.h3`
  font-family: 'Avenir Next Bold';
  font-size: 3.2rem;
  color: ${props => props.color || '#3A475C'};
  margin: 0;
  line-height: 100%;

  ${({ needMedia = true }) =>
    needMedia
      ? `
  @media only screen and (max-width: 978px) {
    font-size: 3.6rem;
  }
  @media only screen and (max-width: 768px) {
    font-size: 4rem;
  }
  @media only screen and (max-width: 576px) {
    font-size: 4.4rem;
  }
  @media only screen and (max-width: 480px) {
    font-size: 5rem;
  }`
      : ''}
`

export const BoldTitle28 = styled.h3`
  font-family: Avenir Next Bold;
  font-size: 2.8rem;
  color: ${props => props.color || '#3A475C'};
  margin: 0;
  line-height: 100%;
`

export const BoldTitle20 = styled.span`
  font-family: Avenir Next Bold;
  font-size: 2rem;
  color: ${props => props.color || '#3A475C'};
  margin: 0;
  line-height: 100%;
  padding-top: ${props => props.paddingTop || 'none'};

  @media only screen and (max-width: 600px) {
    font-size: 2.5rem;
  }

  @media only screen and (max-width: 400px) {
    font-size: 3rem;
  }
`

export const BoldTitle16 = styled.h4`
  font-family: Avenir Next Bold;
  font-size: 1.6rem;
  color: ${props => props.color || '#3A475C'};
  margin: 0;
  line-height: 100%;

  @media only screen and (max-width: 600px) {
    font-size: 2rem;
  }

  @media only screen and (max-width: 400px) {
    font-size: 3.5rem;
  }
`

export const BlueLink = styled.a`
  font-family: Avenir Next Medium;
  font-size: 1.6rem;
  color: ${props => props.color || '#16253D'};
  text-decoration: none;
  color: #235dcf;

  @media only screen and (max-width: 1300px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 2rem;
  }

  @media only screen and (max-width: 400px) {
    font-size: 2.4rem;
  }

  @media only screen and (max-width: 350px) {
    font-size: 2.2rem;
  }

  &:hover {
    font-family: 'Avenir Next Bold';
  }
`

export const BluePageLink = styled(Link)`
  font-family: Avenir Next Medium;
  font-size: 1.6rem;
  color: ${props => props.color || '#16253D'};
  text-decoration: none;
  color: #235dcf;

  @media only screen and (max-width: 1300px) {
    font-size: 1.5rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 2rem;
  }

  @media only screen and (max-width: 400px) {
    font-size: 2.4rem;
  }

  @media only screen and (max-width: 350px) {
    font-size: 2.2rem;
  }

  &:hover {
    font-family: Avenir Next Bold;
  }
`

export const SimpleBlueLink = styled(Link)`
  font-family: Avenir Next;
  font-size: 2rem;
  color: ${props => props.color || '#165BE0'};
  text-decoration: underline;
`

// buttons

const GetStartedButton = styled.button`
  font-family: 'Avenir Next Bold';
  font-size: 3.2rem;
  font-feature-settings: 'liga' off;
  padding: 1.4rem 10rem 1.6rem 10rem;
  border-radius: 0.8rem;
  cursor: pointer;

  @media only screen and (max-width: 620px) {
    width: 100%;
    font-size: 4.2rem;
  }
`

export const BlueGetStartedButton = styled(GetStartedButton)`
  color: #fff;
  background: #0e02ec;
  box-shadow: 0.8rem 0.8rem 1.6rem #afbfda, -0.8rem -0.8rem 1.6rem #ffffff;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 1.6rem;

  @media only screen and (max-width: 620px) {
    border-width: 0.4rem;
  }

  transition: 0.3s all ease-in;

  &:hover {
    box-shadow: 0.2rem 0.2rem 0.4rem #afbfda, -0.2rem -0.2rem 0.4rem #ffffff;
    transition: 0.3s all ease-in;
    background: #651ce4;
  }

  &:focus {
    box-shadow: 0.1rem 0.1rem 0.2rem #afbfda, -0.1rem -0.1rem 0.2rem #ffffff;
    transition: 0.3s all ease-in;
    background: #4100b3;
  }
`

export const GreenGetStartedButton = styled(GetStartedButton)`
  color: ${props => props.theme.colors.white};
  background:${props => props.background || '#3DB0A2'};
  box-shadow: 10px 11px 18px -10px rgba(138,136,138,1);
  // border: 0.2rem solid ${props => props.theme.colors.red};
  // box-shadow: 0.8rem 0.8rem 1.6rem rgba(45, 2, 2, 0.24),
  //   -0.8rem -0.8rem 1.6rem #ffffff, inset 0.4rem 0.4rem 1.6rem #ab3737,
  //   inset -0.4rem -0.4rem 2.4rem rgba(254, 205, 205, 0.25);

  @media only screen and (max-width: 620px) {
    border-width: 0.4rem;
  }

  transition: 0.3s all ease-in;

  &:hover {
  
  }

  &:focus {
    
  }
`
export const BlueButtonLink = styled.a`
  font-size: 2.6rem;
  font-family: Avenir Next Light;
  color: #edf0f2;
  text-align: center;
  display: flex;
`

export const WhiteGetStartedButton = styled(GetStartedButton)`
  display: flex;
  align-items: center;
  font-size: 2.4rem;
  padding: 1rem 1.6rem;
  border: 0.2rem solid ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.white};
  background: inherit;
  margin-left: 1.6rem;
`

export const WhiteLine = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  margin: 1.5rem 0;
  width: calc(100%);
  border: 0.1rem solid #eaeef2;
  box-shadow: 0.4rem 0.4rem 1.4rem #d1d9e6,
    -0.4rem -0.4rem 0.8rem rgba(255, 255, 255, 0.71);

  ${props => props.forFeatures && 'width: calc(100% - 66rem)'};
`

export const TradingFeeButton = styled.button`
  position: fixed;
  bottom: -3rem;
  left: 2rem;
  z-index: 5;
  display: none;
  flex-direction: column;
  border: none;
  border-radius: 1.2rem 1.2rem 0 0;
  background: ${props => props.theme.colors.red};
  padding: 1rem 1rem 4rem 1rem;
  cursor: pointer;
  transition: bottom 0.4s ease-out;

  &:not(.fixed):hover {
    bottom: -2rem !important;
    padding: 1.2rem 1rem 4rem 1rem;
    transition: bottom 0.4s ease-out, padding 0.4s ease-out !important;
  }
`

export const TradingFeeContainer = styled.div`
  position: fixed;
  transform: translateY(100%);
  bottom: 0;
  z-index: 7;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  min-height: 26rem;
  background: ${props => props.theme.colors.red};
  clip-path: ellipse(55% 90% at 50% 100%);
  transition: transform 0.4s ease-out;
  overflow: hidden;

  @media only screen and (max-width: 1200px) {
    clip-path: ellipse(75% 90% at 50% 100%);
  }

  @media only screen and (max-width: 790px) {
    clip-path: ellipse(80% 95% at 50% 100%);
  }

  @media only screen and (max-width: 400px) {
    clip-path: ellipse(95% 100% at 50% 100%);
  }
`

export const Overlay = styled.div`
  opacity: 0;
  background: #000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 6;
  transition: opacity 0.4s, visibility 0.4s;
  overflow: hidden;
`
