import { API_URL } from './config'

const getStakingAPRQuery = `
    query getStakingAPR {
        getStakingAPR {
            totalStaked
            totalStakedUSD
            buyBackAmountWithoutDecimals
            buyBackDailyRewards
            treasuryDailyRewards
            totalDailyRewards
            buyBackAPR
            treasuryAPR
            totalStakingAPR
        }
    }
`

const getStakingAPRResolver = async () => {
  return await fetch(`${API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      operationName: 'getStakingAPR',
      query: getStakingAPRQuery,
    }),
  })
    .then((data) => data.json())
    .then((data) => {
      return data
    })
}

export const getStakingAPR = async () => {
  const stakingAprData = await getStakingAPRResolver()

  return stakingAprData.data.getStakingAPR
}
