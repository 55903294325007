import { API_URL } from "./config"

export const getCCAICirculationSupply = () => {
    const result = fetch(
      `${API_URL}/getCCAICirculationSupply`,
      {
        method: 'GET',
      }
    )
      .then(data => data.json())
      .then(data => data.supply)
      .catch(function(error) {
        console.log('Request failed', error)
      })
  
    return result
  }
  