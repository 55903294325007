export default {
  breakpoints: ['40em', '52em', '64em'],
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  colors: {
    red: '#F07878',
    blue: '#7380EB',
    darkBlue: '#7380EB',
    darkSky: '#2452AD',
    darkestBlue: '#19408A',
    sky: '#E7ECF3',
    gray: '#7284A0',
    textGray: '#93A0B3',
    eerie: '#15181E',
    dark: '#16253D',
    darker: '#3E4B60',
    background: '#F7FAFD',
    green: '#3DB0A2',
    light: '#fafafa',
    snow: '#f9fbfd',
    white: '#EAEEF2',
    darketWhite: '#E7ECF3',

  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    sans: 'system-ui, sans-serif',
    mono: 'Menlo, monospace',
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
}
