import React from 'react'
import styled from 'styled-components'
import Logo from '../images/logo/aldrinBlack.svg'
import { Analytics } from './Analytics'

const MainContainer = styled.div`
  padding: 0 10rem;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    padding: 0 3rem;
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }
`
const FooterContainer = styled.div`
  display: flex;
  gap: 6rem;
  width: 40%;
  align-items: center;
  justify-content: center;
  z-index: 3;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`

const FooterLinks = styled.a`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 1.1rem;
  color: #282828;
  display: flex;
  justify-content: flex-start;

  &:hover {
    color: #0e02ec;
  }

  @media only screen and (max-width: 1600px) {
    font-size: 1.2rem;
  }

  @media only screen and (max-width: 600px) {
    font-size: 1.8rem;
  }
`

const LogoBlock = styled.div`
  display: flex;
  flex-direction: column;

  .Logo {
    width: 60%;
    margin-bottom: 1rem;
  }

  .LinkRow {
    gap: 1rem;
  }

  @media only screen and (max-width: 600px) {
    gap: 1.8rem;
    order: 3;

    .Logo {
      width: 60%;
    }

    .LinkRow {
      gap: 2.8rem;
    }
  }
`

const MediaBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 2.6rem;
  z-index: 3;

  a {
    &:hover {
      svg {
        path {
          fill: #717171;
        }
      }
    }

    &:active {
      svg {
        path {
          fill: #9e9e9e;
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    width: 35%;
    justify-content: space-between;
    margin-bottom: 6rem;
  }
`

const TitleText = styled.div`
  font-family: 'Inter';
  color: #797979;
  font-size: 1.5rem;

  a {
    &:hover {
      color: #551ed9;
    }
  }

  a {
    &:active {
      color: #4719b5;
    }
  }
`

const Footer = () => {
  return (
    <MainContainer>
      <LogoBlock>
        <a href="https://aldrin.com/" rel="noopener noreferrer" target="_blank">
          <img src={Logo} className="Logo" />
        </a>
      </LogoBlock>
      <FooterContainer>
        <TitleText>
          BuildingBlock by{' '}
          <a
            href="https://aldrin.com/"
            rel="noopener noreferrer"
            target="_blank"
            style={{ color: '#6423FF', fontWeight: 600 }}
          >
            Aldrin
          </a>
        </TitleText>
      </FooterContainer>
      <MediaBlock>
        <a
          href="https://twitter.com/Aldrin_Exchange"
          rel="noopener noreferrer"
          target="_blank"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.633 7.99698C19.646 8.17198 19.646 8.34598 19.646 8.51998C19.646 13.845 15.593 19.981 8.186 19.981C5.904 19.981 3.784 19.32 2 18.172C2.324 18.209 2.636 18.222 2.973 18.222C4.78599 18.2264 6.54764 17.6201 7.974 16.501C7.13342 16.4858 6.31857 16.2084 5.64324 15.7077C4.9679 15.207 4.46578 14.5079 4.207 13.708C4.456 13.745 4.706 13.77 4.968 13.77C5.329 13.77 5.692 13.72 6.029 13.633C5.11676 13.4488 4.29647 12.9543 3.70762 12.2337C3.11876 11.513 2.79769 10.6106 2.799 9.67998L2.799 9.62998C3.336 9.92898 3.959 10.116 4.619 10.141C4.06609 9.77357 3.61272 9.27501 3.29934 8.68977C2.98596 8.10454 2.82231 7.45084 2.823 6.78698C2.823 6.03898 3.022 5.35298 3.371 4.75498C4.38314 6 5.6455 7.01854 7.07634 7.74464C8.50717 8.47074 10.0746 8.8882 11.677 8.96998C11.615 8.66998 11.577 8.35898 11.577 8.04698C11.5767 7.51794 11.6807 6.99404 11.8831 6.50522C12.0854 6.01641 12.3821 5.57226 12.7562 5.19817C13.1303 4.82408 13.5744 4.52739 14.0632 4.32506C14.5521 4.12273 15.076 4.01872 15.605 4.01898C16.765 4.01898 17.812 4.50498 18.548 5.29098C19.4498 5.1166 20.3145 4.78741 21.104 4.31798C20.8034 5.2488 20.1738 6.03809 19.333 6.53798C20.1328 6.44676 20.9144 6.23644 21.652 5.91398C21.1011 6.71708 20.4185 7.42133 19.633 7.99698V7.99698Z"
              fill="#1D1D1D"
            />
          </svg>
        </a>
        <a
          href="https://www.linkedin.com/company/aldrin-rin"
          rel="noopener noreferrer"
          target="_blank"
        >
          <svg
            width="19"
            height="18"
            viewBox="0 0 19 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3.95833 1.95652C3.95833 3.05217 3.0875 3.91304 1.97917 3.91304C0.870833 3.91304 0 3.05217 0 1.95652C0 0.86087 0.870833 0 1.97917 0C3.0875 0 3.95833 0.86087 3.95833 1.95652ZM3.95833 5.47826H0V18H3.95833V5.47826ZM10.2917 5.47826H6.33333V18H10.2917V11.4261C10.2917 7.74783 15.0417 7.43478 15.0417 11.4261V18H19V10.0957C19 3.91304 11.9542 4.14783 10.2917 7.2V5.47826Z"
              fill="#1D1D1D"
            />
          </svg>
        </a>
        <a
          href="https://t.me/Aldrin_Exchange"
          rel="noopener noreferrer"
          target="_blank"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20.6646 3.717L2.93461 10.554C1.72461 11.04 1.73161 11.715 2.71261 12.016L7.26461 13.436L17.7966 6.791C18.2946 6.488 18.7496 6.651 18.3756 6.983L9.84261 14.684L9.84061 14.684L9.84261 14.685L9.52861 19.377C9.98861 19.377 10.1916 19.166 10.4496 18.917L12.6606 16.767L17.2596 20.164C18.1076 20.631 18.7166 20.391 18.9276 19.379L21.9466 5.151C22.2556 3.91201 21.4736 3.351 20.6646 3.717V3.717Z"
              fill="#1D1D1D"
            />
          </svg>
        </a>
      </MediaBlock>
    </MainContainer>
  )
}

export default Footer
