import React from 'react'
import styled from 'styled-components'

import StyleProvider from '../styles/base.styles'
import NavBar from './NavBar'
import Footer from './Footer'
import Helmet from 'react-helmet'

const MainContainer = styled.div``

const Layout = props => {
  return (
    <StyleProvider>
      <Helmet>
        <title>{`${'Aldrin | Building Blocks'}`}</title>
        <meta name="description" content="Aldrin Token." />
        {/* favicons*/}
        {/* For the dark & light themes */}
      </Helmet>

      <NavBar />
      {props.children}
      <Footer />
    </StyleProvider>
  )
}

export default Layout
