import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, RowContainer } from '../../components/Common'
import Arrow from '../../images/arrow.svg'
import SolanaIcon from '../../images/solana.svg'

const TextBlock = styled.div`
  width: ${props => props.width || '47%'};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin: ${props => props.margin || '0'};

  @media only screen and (max-width: 600px) {
    width: ${props => props.width || '100%'};
  }
`

const SlideContainer = styled.div`
position: absolute;
  width: 67%;
  height: ${props => (props.selected ? 'auto' : '50rem')};
  border-radius: 4rem;
  display: flex
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    138.96deg,
    rgba(122, 122, 122, 1) -34.91%,
    rgba(29, 29, 29, 1) 25.53%,
    rgba(0, 0, 0, 1) 109.54%
  );
  box-shadow: 95px 100px 90px 25px rgb(179 125 250 / 15%);

  z-index: ${props => (props.selected ? '10' : '9')};
  opacity: ${props => (props.selected ? '1' : '0.3')};
  transform:${props => (props.selected ? 'translate(25%)' : 'translate(30%)')};
  transition: ease-out 0.5s;



  @media only screen and (max-width: 600px) {
    width: 85%;
    height: ${props => (props.selected ? 'auto' : '80rem')};
    transform:${props => (props.selected ? 'translate(9%)' : 'translate(12%)')};
    border-radius: 6rem;
  }
`

const StyledHeaderOfSlide = styled.div`
  width: 100%;
  height: 10rem;
  background: linear-gradient(
    142.03deg,
    rgba(187, 135, 254, 1),
    rgba(81, 15, 238, 1)
  );
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  border-radius: 4rem 4rem 0 0;

  @media only screen and (max-width: 600px) {
    height: 17rem;
    border-radius: 6rem 6rem 0 0;
  }
`

const SliderContainer = styled.div`
  width: 48%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  position: relative;

  .left {
    margin-right: 5rem;
    cursor: pointer;
  }

  .right {
    margin-left: 5rem;
    transform: rotate(180deg);
    cursor: pointer;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    height: 123rem;

    .mobile-arrows {
      position: absolute;
      bottom: 1rem;
      transform: translate(50%);
    }

    .left,
    .right {
      display: none;
    }
  }
`

const StyledIcon = styled.img`
  position: absolute;
  top: 3rem;
  left: 3rem;
  width: 4rem;
  height: 4rem;
`

const MobileArrows = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (min-width: 600px) {
    display: none;
  }
`

const Description = styled.div`
  position: absolute;
  right: 26%;
  top: 34rem;
  width: 25rem;
  text-align: center;
  user-select: none;

  @media only screen and (min-width: 1920px) {
    right: 25%;
  }
  @media only screen and (max-width: 600px) {
    right: 22%;
    top: 105rem;
    width: 34rem;
  }
`

const TitleText = styled.span`
  font-family: 'Inter';
  color: ${props => props.color || '#717171'};
  font-size: ${props => props.fontSize || '1.5rem'};
  line-height: ${props => props.height || '2.5rem'};
  text-align: left;
  margin-top: ${props => props.marginTop || '1rem'};
  font-weight: ${props => props.weight || 'normal'};

  @media only screen and (max-width: 600px) {
    line-height: 3.8rem;
    font-size: 2.4rem;

    &.value-title {
      font-size: 2.8rem;
      margin: 0.5rem 0;
    }
  }
`

export const GetAccessBtn = styled.button`
  display: inline-flex;
  justify-content: center;
  width: 100%;
  height: 4rem;
  align-items: center;
  color: #fff;
  font-family: 'Inter';
  font-weight: 600;
  font-size: 1.3rem;
  border-radius: 18px;
  gap: 0.5rem;
  letter-spacing: 0.7px;
  background: linear-gradient(276.11deg, #fea987 -4.52%, #6423ff 92.81%);
  margin-top: 2rem;
  cursor: pointer;

  @media only screen and (max-width: 600px) {
    height: 6rem;
    margin: 4rem 0 1rem 0;
    font-size: 2rem;
  }
`

const SliderDot = styled.div`
  width: ${props => (props.selected ? '2.2rem' : '1.1rem')};
  height: 0;
  border: 2px solid #6423ff;
  border-radius: 1rem;
  opacity: ${props => (props.selected ? '1' : '0.7')};
  transition: 0.3s;
`
const DotsContainer = styled.div`
  position: absolute;
  right: 44%;
  top: 31.5rem;
  width: 6rem;
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 600px) {
    display: none;
  }
`

const HeaderOfSlide = ({ text, title, margin }: { text: string[]; title: string, margin?: string }) => {
  return (
    <StyledHeaderOfSlide>
      <StyledIcon src={SolanaIcon} />
      <TextBlock width="auto" margin={margin}>
        <TitleText
          height="2rem"
          weight={700}
          fontSize="2rem"
          color="#F9F8FF"
          marginTop="0"
        >
          {title}
        </TitleText>
        <TitleText height="2rem" color="#F9F8FF" marginTop="0">
          {text[0]}
        </TitleText>
        <TitleText height="2rem" color="#F9F8FF" marginTop="0">
          {text[1]}
        </TitleText>
      </TextBlock>
    </StyledHeaderOfSlide>
  )
}

const Slide = ({
  headerTitle,
  headerText,
  description,
  montsIncome,
  yearIncome,
  selected,
  margin
}: {
  headerTitle: string
  headerText: string[]
  description: string
  montsIncome: string[]
  yearIncome: string[]
  selected: boolean
  margin?: string
}) => {
  return (
    <SlideContainer selected={selected}>
      <HeaderOfSlide margin={margin} text={headerText} title={headerTitle} />
      <TextBlock style={{ padding: '3rem' }} width="100%">
        <TitleText
          color="#B3B2B2"
          height="2.2rem"
          fontSize="1.4rem"
          marginTop="0"
        >
          {description}
        </TitleText>
        <TextBlock width="100%">
          <TitleText
            color="#9E9E9E"
            height="2.2rem"
            fontSize="1.4rem"
            marginTop="3rem"
          >
            Your Assets
          </TitleText>
          <TitleText
            fontSize="2rem"
            color="#FFFFFF"
            weight={600}
            marginTop="0.5rem"
            className="value-title"
          >
            <span style={{ color: '#B3B2B2' }}>$</span> 10,000.00 USD
          </TitleText>
        </TextBlock>
        <TextBlock width="100%">
          <TitleText color="#9E9E9E" height="2.2rem" fontSize="1.4rem">
            3 Month Performance*{' '}
          </TitleText>
          <RowContainer justify="space-between">
            <TitleText
              fontSize="2rem"
              color="#FFFFFF"
              weight={600}
              marginTop="0.5rem"
              className="value-title"
            >
              <span style={{ color: '#B3B2B2' }}>$</span> {montsIncome[0]} USD
            </TitleText>
            <TitleText
              fontSize="2rem"
              color="#54EFA5"
              weight={400}
              marginTop="0.5rem"
            >
              {montsIncome[1]}
            </TitleText>
          </RowContainer>
        </TextBlock>
        <TextBlock width="100%">
          <TitleText color="#9E9E9E" height="2.2rem" fontSize="1.4rem">
            1 Year Performance*{' '}
          </TitleText>
          <RowContainer justify="space-between">
            <TitleText
              fontSize="2rem"
              color="#FFFFFF"
              weight={600}
              marginTop="0.5rem"
              className="value-title"
            >
              <span style={{ color: '#B3B2B2' }}>$</span> {yearIncome[0]} USD
            </TitleText>
            <TitleText
              fontSize="2rem"
              color="#54EFA5"
              weight={400}
              marginTop="0.5rem"
            >
              {yearIncome[1]}
            </TitleText>
          </RowContainer>
        </TextBlock>
        <GetAccessBtn>deposit</GetAccessBtn>
      </TextBlock>
    </SlideContainer>
  )
}

export const Slider = ({}) => {
  const [slideToShow, changeSlide] = useState(0)
  return (
    <SliderContainer>
      <img
        className="left"
        src={Arrow}
        onClick={() => {
          if (slideToShow <= 0) {
            changeSlide(2)
          } else {
            changeSlide(slideToShow - 1)
          }
        }}
      />
      <Slide
        headerText={['8.75% Fixed APR', 'Fixed asset price']}
        headerTitle="FOUNDATION"
        description="Start earning a high APR through crypto in the safest way possible and sit back whilst we do the work. Your funds remain in an asset that is pegged to the US dollar. Our lowest risk option."
        montsIncome={['223.00', '+2.23%']}
        yearIncome={['911.00', '+9.11%']}
        selected={slideToShow === 0}
      />
      <Slide
        headerText={['12% Fixed APR', 'Variable asset price']}
        headerTitle="DYNAMIC"
        description="The Dynamic Block is a complex structure, where we take care of all the work for you. Earn a higher APR and take advantage of the growing interest in Solana, the fastest blockchain. "
        montsIncome={['672.00', '+6.72%']}
        yearIncome={['50,700', '+507%']}
        selected={slideToShow === 1}
      />
      <Slide
        headerText={['15% to 40% Variable APR', 'Variable asset price']}
        headerTitle="COFFEE MONEY"
        description="The Coffee Money Block is your way to earn up to 40% yield and get in on the ground floor of a crypto company. This is high risk so please just use your coffee money. "
        montsIncome={['503.00', '+5%']}
        yearIncome={['NA', 'NA%']}
        selected={slideToShow === 2}
        margin={'0 0 0 4rem'}
      />
      <img
        className="right"
        src={Arrow}
        onClick={() => {
          if (slideToShow >= 2) {
            changeSlide(0)
          } else {
            changeSlide(slideToShow + 1)
          }
        }}
      />
      <MobileArrows className="mobile-arrows">
        <img
          src={Arrow}
          onClick={() => {
            if (slideToShow <= 0) {
              changeSlide(2)
            } else {
              changeSlide(slideToShow - 1)
            }
          }}
        />
        <img
          src={Arrow}
          style={{ transform: 'rotate(180deg)' }}
          onClick={() => {
            if (slideToShow >= 2) {
              changeSlide(0)
            } else {
              changeSlide(slideToShow + 1)
            }
          }}
        />
      </MobileArrows>
      <DotsContainer>
        <SliderDot selected={slideToShow === 0} />
        <SliderDot selected={slideToShow === 1} />
        <SliderDot selected={slideToShow === 2} />
      </DotsContainer>
      <Description>
        <TitleText height="2rem" color="#9E9E9E">
          *Based on historical numbers and compounded monthly.
        </TitleText>
      </Description>
    </SliderContainer>
  )
}
