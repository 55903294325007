export const importCoinIcon = coinName => {
  let urlOfCoinIcon = `https://app.aldrin.com/cryptocurrency-icons/svg/icon/${coinName.toLowerCase()}.svg`

  return urlOfCoinIcon
}

export const getImportCoinUrlForGenericIcon = () =>
  `https://app.aldrin.com/cryptocurrency-icons/svg/icon/generic.svg`

export const onErrorImportCoinUrl = e => {
  const src = getImportCoinUrlForGenericIcon()
  const iconSrc = src.replace(/"/gi, '')
  e.target.src = iconSrc
}

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const validate = values => {
  let errors = {}

  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address'
  }

  if (!values.name) {
    errors.name = 'Required'
  }

  if (!values.message) {
    errors.message = 'Required'
  }

  return errors
}

export function validateEmail(inputText) {
  var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  if (inputText.match(mailformat)) {
    return true
  } else {
    return false
  }
}

export const encode = (data) => {
  return Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join('&')
}