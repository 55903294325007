import React, { useState } from 'react'
import styled from 'styled-components'
import User from '../../images/user-octagon.svg'
import Plan from '../../images/receipt.svg'
import Income from '../../images/buy-crypto.svg'
import { GetAccessBtn } from './Main'
import { GetAccessPopup } from '../../components/GetAccessPopup'
import Back from '../../images/lowBack.png'

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 0 15rem 30rem 15rem;
  background-image: url(${Back});
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;

  @media (min-width: 1600px) {
    padding: 0 25rem 10rem 25rem;
  }

  @media (max-width: 600px) {
    padding: 10rem 3rem;
  }
`

const CardsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 10rem;

  @media (min-width: 1600px) {
    padding: 10rem 0;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    padding: 10rem 5rem;
  }
`

const Title = styled.span`
  font-family: 'Montserrat Bold';
  font-weight: 700;
  color: #000;
  font-size: 4rem;
  line-height: 5rem;

  @media (max-width: 600px) {
    font-size: 4.7rem;
    line-height: 5.5rem;
  }
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 50%;

  @media (max-width: 600px) {
    display: none;
  }
`

const TitleContainerMobile = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  @media (min-width: 600px) {
    display: none;
  }
`

const Card = styled.div`
  width: 26rem;
  height: 27rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(
    320deg,
    rgba(81, 15, 238, 1),
    rgba(187, 135, 254, 1)
  );
  border: 0.2rem solid;
  border-radius: 4rem;
  border-image: linear-gradient(
    320deg,
    rgba(100, 35, 255, 1),
    rgba(235, 219, 255, 0.69)
  );
  box-shadow: 0px 30px 40px 0px rgba(179, 125, 250, 0.3);
  margin: ${props => props.margin || '0'};
  position: relative;
  padding: 0 2rem;

  @media (min-width: 1600px) {
    width: 26rem;
    height: 27rem;
  }

  @media (max-width: 600px) {
    width: 100%;
    margin: 7rem 0 0 0;
    height: 40rem;
    padding: 0 4rem;
  }
`

const WhiteMark = styled.div`
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  text-align: center;
  background: #fff;
  font-size: 2.5rem;
  color: #6423ff;
  font-family: 'Inter';
  font-weight: 500;
  user-select: none;

  @media (max-width: 600px) {
    width: 7rem;
    height: 7rem;
    font-size: 3.5rem;
  }
`

const MarkWrapper = styled.div`
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    225deg,
    rgba(232, 232, 232, 0.5) 0%,
    rgba(255, 255, 255, 0.5) 100%
  );
  backdrop-filter: blur(8px);
  bottom: 23.5rem;

  @media (max-width: 600px) {
    bottom: 35rem;
    width: 10rem;
    height: 10rem;
  }
`

const Header = styled.span`
  font-family: 'Inter';
  font-weight: 600;
  color: #fff;
  font-size: 2.3rem;
  margin-top: 1.5rem;

  @media (max-width: 600px) {
    font-size: 3.3rem;
  }
`

const Text = styled.span`
  font-family: 'Inter';
  font-weight: 400;
  color: #fff;
  font-size: 1.5rem;
  text-align: center;
  margin-top: 1.5rem;

  @media (max-width: 600px) {
    font-size: 2.3rem;
  }
`

export const StyledCard = ({ number, margin = '0', children }) => {
  return (
    <Card margin={margin}>
      <MarkWrapper>
        <WhiteMark>{number}</WhiteMark>
      </MarkWrapper>
      {children}
    </Card>
  )
}

export const HowToStart = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  return (
    <Container>
      <TitleContainer>
        <Title>How to start</Title>
        <Title>with BuildingBlock</Title>
      </TitleContainer>
      <TitleContainerMobile>
        <Title>How to start with</Title>
        <Title>BuildingBlock</Title>
      </TitleContainerMobile>
      <CardsContainer>
        <StyledCard margin={'0 2rem 0 0'} number="1">
          <img src={User} />
          <Header>Create Wallet</Header>
          <Text>
            Create a non-custodial wallet & fund your account, which remain in
            your control
          </Text>
        </StyledCard>
        <StyledCard number="2" margin="5rem 0 0 0">
          <img src={Plan} />
          <Header>Choose a Plan</Header>
          <Text>
            We have three plans that make it easier to start investing
          </Text>
        </StyledCard>
        <StyledCard margin={'0 0 0 2rem'} number="3">
          <img src={Income} />
          <Header>Earn Income</Header>
          <Text>
            Monthly you can withdraw your income, your funds or compound your
            earnings
          </Text>
        </StyledCard>
      </CardsContainer>
      <GetAccessBtn
        onClick={() => {
          setIsPopupOpen(true)
        }}
      >
        get access
      </GetAccessBtn>
      <GetAccessPopup
        open={isPopupOpen}
        close={() => {
          setIsPopupOpen(false)
        }}
      />
    </Container>
  )
}
